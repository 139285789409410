module.exports = {
  siteTitle: 'Dan D Kim', // <title>
  shortSiteTitle: 'Dan D Kim Website', // <title> ending for posts and pages
  siteDescription: 'Here is a story',
  siteUrl: 'https://dandkim.com',
  pathPrefix: '',
  siteImage: 'danguin.jpg',
  siteLanguage: 'en',

  /* author */
  authorName: 'dongwook daniel kim',
  authorTwitterAccount: 'dougouk',

  /* info */
  headerTitle: 'Dan D Kim',
  headerSubTitle: "Let's share stories",

  /* manifest.json */
  manifestName: 'Dan D Kim',
  manifestShortName: 'DanDKim', // max 12 characters
  manifestStartUrl: '/index.html',
  manifestBackgroundColor: 'white',
  manifestThemeColor: '#666',
  manifestDisplay: 'standalone',

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  gravatarImgMd5: 'https://www.gravatar.com/avatar/c7c21de87d5b468819e20f91a3c33738',

  // social
  authorSocialLinks: [
    { name: 'github', url: 'https://github.com/dougouk' },
    { name: 'twitter', url: 'https://twitter.com/dougouk' },
    { name: 'facebook', url: 'http://facebook.com/greglobinski' },
    { name: 'linkedin', url: 'https://www.linkedin.com/in/dougouk' }
  ]
}
