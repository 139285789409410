import css from 'styled-jsx/css'

export const globalStyles = css.global`
  body.light {
    --color-brand-primary: #1976d2;
    --color-brand-primaryActive: #1976d2;
    --color-brand-light: #62a3ff;
    --color-brand-lightActive: #62a3ff;
    --color-brand-dark: #000;
    --color-special-attention: #e8810c;
    --color-neutral-white: #fff;
    --color-neutral-gray-a: #fafaf9;
    --color-neutral-gray-b: #f3f2f2;
    --color-neutral-gray-c: #ecebea;
    --color-neutral-gray-d: #dddbda;
    --color-neutral-gray-e: #c9c7c5;
    --color-neutral-gray-f: #b0adab;
    --color-neutral-gray-g: #969492;
    --color-neutral-gray-h: #706e6b;
    --color-neutral-gray-i: #514f4d;
    --color-neutral-gray-j: #3e3e3c;
    --color-neutral-gray-k: #2b2826;
    --text-color-primary: var(--color-neutral-gray-j);
    --text-color-primary-inverse: #fff;
    --text-color-brand: var(--color-brand-primary);
    --text-color-attention: orange;
    --remark-code-wrap-background-color: var(--color-neutral-gray-c);
    --background-color-primary: var(--color-neutral-white);
    --background-color-alt: #fafaf9;
    --background-color-brand: var(--color-brand-primary);
    --line-color: #ecebea;
    --icon-color: var(--color-brand-primary);
    --hero-h1-color: #fff;
    --hero-h2-color: #fff;
    --blog-h1-hoverColor: var(--color-brand-primary);
    --blog-h2-hoverColor: var(--color-brand-primary);
    --card-shadow: #f3f2f2;
    --svg-color: var(--color-brand-dark);
    background-color: var(--background-color-primary);
  }

  body.dark {
    --color-brand-primary: #53a7eb;
    --color-brand-primaryActive: #53a7eb;
    --color-special-attention: orange;
    --color-neutral-white: #e8e6e3;
    --color-neutral-gray-a: #fafaf9;
    --color-neutral-gray-b: #f3f2f2;
    --color-neutral-gray-c: #ecebea;
    --color-neutral-gray-d: #dddbda;
    --color-neutral-gray-e: #c9c7c5;
    --color-neutral-gray-f: #b0adab;
    --color-neutral-gray-g: #969492;
    --color-neutral-gray-h: #706e6b;
    --color-neutral-gray-i: #514f4d;
    --color-neutral-gray-j: #3e3e3c;
    --color-neutral-gray-k: #2b2826;
    --color-neutral-gray-l: #232628;
    --color-light-gray: #c1bcb4;
    --text-color-primary: var(--color-light-gray);
    --text-color-primary-inverse: #fff;
    --text-color-brand: var(--color-brand-primary);
    --text-color-attention: orange;
    --remark-code-wrap-background-color: var(--color-neutral-gray-l);
    --background-color-primary: #131516;
    --background-color-alt: #1e2122;
    --background-color-brand: var(--color-brand-primary);
    --line-color: #363a3c;
    --icon-color: var(--color-brand-primary);
    --hero-h1-color: #fff;
    --hero-h2-color: #fff;
    --blog-h1-hoverColor: var(--color-brand-primary);
    --blog-h2-hoverColor: var(--color-brand-primary);
    --card-shadow: #1f2223;
    --svg-color: var(--color-neutral-white);
    background-color: var(--background-color-primary);
  }

  h1,
  h2,
  h3,
  h4,
  p,
  summary,
  details,
  ol,
  ul,
  span:not(.token), /* avoid highlighting code blocks */
  table {
    color: var(--text-color-primary);
  }
`
