import PropTypes from 'prop-types'
import React from 'react'

const Footer = props => {
  const { html, theme } = props

  return (
    <>
      <footer className='footer' dangerouslySetInnerHTML={{ __html: html }} />

      {/* --- STYLES --- */}
      <style jsx>{`
        .footer {
          background: var(--background-color-alt);
          padding: ${theme.space.inset.default};
          padding-top: 0;
          padding-bottom: 100px;

          :global(ul) {
            list-style: none;
            text-align: center;
            padding: 0;

            :global(li) {
              color: var(--color-neutral-gray-g);
              font-size: ${theme.font.size.xxs};
              padding: ${theme.space.xxs} ${theme.space.s};
              position: relative;
              display: inline-block;

              &::after {
                content: '•';
                position: absolute;
                right: ${`calc(${theme.space.xs} * -1)`};
              }
              &:last-child::after {
                content: '';
              }
            }
          }
        }

        @from-width desktop {
          .footer {
            padding: 0 1em 1.5em;
          }
        }
      `}</style>
    </>
  )
}

Footer.propTypes = {
  html: PropTypes.string,
  theme: PropTypes.object.isRequired,
}

export default Footer
