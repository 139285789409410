/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// TODO: require https://www.gatsbyjs.com/plugins/gatsby-plugin-ts-config/ for typescript conversion
// import './src/theme/tailwind.css'
// import { GatsbyBrowser } from 'gatsby'

// export const onServiceWorkerUpdateFound: GatsbyBrowser['onServiceWorkerUpdateFound'] = () => {
//   document.getElementById('___gatsby').setAttribute('data-update-available', 'true')
//   console.info('PWA update available.')
// }

require('./src/theme/tailwind.css')

exports.onServiceWorkerUpdateFound = () => {
  document.getElementById('___gatsby').setAttribute('data-update-available', 'true')
  console.info('PWA update available.')
}
