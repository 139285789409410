import React from 'react'
import { FaMoon, FaSun } from 'react-icons/fa'

const THEMES = {
  LIGHT: 'light',
  DARK: 'dark',
}

const ThemeToggle = () => {
  // window is null in server-side rendering
  const hasWindow = () => typeof window !== 'undefined'

  const initialTheme = hasWindow() ? window.localStorage.getItem('theme') : 'light'
  const [theme, updateTheme] = React.useState(initialTheme)

  const handleThemeToggle = () => {
    let themeToRemove
    let themeToAdd

    if (theme === THEMES.LIGHT) {
      themeToRemove = THEMES.LIGHT
      themeToAdd = THEMES.DARK
    } else {
      themeToRemove = THEMES.DARK
      themeToAdd = THEMES.LIGHT
    }

    updateTheme(themeToAdd)
    document.body.classList.remove(themeToRemove)
    document.body.classList.add(themeToAdd)

    // window null in server-side rendering
    if (hasWindow()) {
      window.localStorage.setItem('theme', themeToAdd)
    }
  }

  return (
    <>
      <button className='button' aria-label='Light or Dark Mode Toggle' onClick={handleThemeToggle}>
        {theme === 'light' ? <FaSun /> : <FaMoon />}
      </button>
      <style jsx>
        {`
          .button {
            display: flex;
            align-items: center;
            background-color: transparent;
            border: none;
            outline: none;
            padding: 10px;

            :global(svg) {
              width: 1.5em;
              height: 1.5em;
              opacity: 0.5;
              fill: var(--svg-color);

              &:hover {
                opacity: 1;
              }
            }

            :global(.homepage):not(.fixed) & :global(svg) {
              fill: var(--color-neutral-white);
            }
          }
        `}
      </style>
    </>
  )
}

export default ThemeToggle
