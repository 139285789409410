import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import 'typeface-open-sans'
import FontFaceObserver from 'fontfaceobserver'

import Footer from '../components/Footer/'
import Header from '../components/Header'
import { getScreenWidth, timeoutThrottlerHandler } from '../utils/helpers'

import { globalStyles } from '../theme/globalStyles'
import themeObjectFromYaml from 'src/theme/theme.yaml'
import { Theme } from 'src/theme/type'

// Define theme type based on your theme structure
// type Theme = typeof themeObjectFromYaml

export const ThemeContext = React.createContext<Theme | null>(null)
export const ScreenWidthContext = React.createContext<number>(0)
export const FontLoadedContext = React.createContext<boolean>(false)

interface LayoutProps {
  children: React.ReactNode
  location: {
    pathname: string
  }
}

interface LayoutState {
  font400loaded: boolean
  font600loaded: boolean
  screenWidth: number
  headerMinimized: boolean
  theme: Theme
}

interface QueryData {
  pages: {
    edges: Array<{
      node: {
        fields: {
          slug: string
          prefix: string
        }
        frontmatter: {
          title: string
          menuTitle: string
        }
      }
    }>
  }
  footnote: {
    id: string
    html: string
  }
}

class Layout extends React.Component<LayoutProps, LayoutState> {
  private timeouts: { [key: string]: NodeJS.Timeout } = {}

  constructor(props: LayoutProps) {
    super(props)

    this.state = {
      font400loaded: false,
      font600loaded: false,
      screenWidth: 0,
      headerMinimized: false,
      theme: themeObjectFromYaml,
    }

    if (typeof window !== `undefined`) {
      this.loadFont('font400', 'Open Sans', 400)
      this.loadFont('font600', 'Open Sans', 600)
    }
  }

  componentDidMount() {
    this.setState({
      screenWidth: getScreenWidth(),
    })
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.resizeThrottler, false)
    }
  }

  resizeThrottler = () => {
    return timeoutThrottlerHandler(this.timeouts, 'resize', 100, this.resizeHandler)
  }

  resizeHandler = () => {
    this.setState({ screenWidth: getScreenWidth() })
  }

  isHomePage = (): boolean => {
    return this.props.location.pathname === '/'
  }

  loadFont = (name: string, family: string, weight: number) => {
    const font = new FontFaceObserver(family, {
      weight: weight,
    })

    font.load(null, 10000).then(
      () => {
        console.log(`${name} is available`)
        this.setState({ [`${name}loaded`]: true } as Pick<LayoutState, keyof LayoutState>)
      },
      () => {
        console.log(`${name} is not available`)
      }
    )
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            pages: allMarkdownRemark(
              filter: { fileAbsolutePath: { regex: "//pages//" }, fields: { prefix: { regex: "/^\\d+$/" } } }
              sort: { fields: [fields___prefix], order: ASC }
            ) {
              edges {
                node {
                  fields {
                    slug
                    prefix
                  }
                  frontmatter {
                    title
                    menuTitle
                  }
                }
              }
            }
            footnote: markdownRemark(fileAbsolutePath: { regex: "/footnote/" }) {
              id
              html
            }
          }
        `}
        render={(data: QueryData) => {
          const { children } = this.props
          const {
            footnote: { html: footnoteHTML },
            pages: { edges: pages },
          } = data

          return (
            <ThemeContext.Provider value={this.state.theme}>
              <FontLoadedContext.Provider value={this.state.font400loaded}>
                <ScreenWidthContext.Provider value={this.state.screenWidth}>
                  <>
                    <Header
                      path={this.props.location.pathname}
                      pages={pages}
                      theme={this.state.theme}
                    />
                    <main>{children}</main>
                    <Footer html={footnoteHTML} theme={this.state.theme} />

                    {/* --- STYLES --- */}
                    <style jsx>{`
                      main {
                        min-height: 80vh;
                      }
                    `}</style>
                    <style jsx global>
                      {globalStyles}
                    </style>
                    <style jsx global>{`
                      html {
                        box-sizing: border-box;
                      }
                      *,
                      *:after,
                      *:before {
                        box-sizing: inherit;
                        margin: 0;
                        padding: 0;
                        transition: all ${this.state.theme.time.duration.default};
                      }
                      body {
                        font-family: ${this.state.font400loaded
                          ? "'Open Sans', sans-serif;"
                          : 'Arial, sans-serif;'};
                      }
                      h1,
                      h2,
                      h3 {
                        font-weight: ${this.state.font600loaded ? 600 : 400};
                        line-height: 1.1;
                        letter-spacing: -0.03em;
                        margin: 0;
                      }
                      h1 {
                        letter-spacing: -0.04em;
                      }
                      p {
                        margin: 0;
                      }
                      strong {
                        font-weight: ${this.state.font600loaded ? 600 : 400};
                      }
                      a {
                        text-decoration: none;
                        color: var(--text-color-brand);
                      }
                      main {
                        width: auto;
                        display: block;
                      }
                    `}</style>
                  </>
                </ScreenWidthContext.Provider>
              </FontLoadedContext.Provider>
            </ThemeContext.Provider>
          )
        }}
      />
    )
  }
}

export default Layout