import React, { useState, useEffect } from 'react'
import { ThemeContext } from '../../layouts'

export const UpdateButton = () => {
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false)
  useEffect(() => {
    const interval = setInterval(() => {
      const isUpdateAvailable =
        document.getElementById('___gatsby').dataset.updateAvailable === 'true'

      if (isUpdateAvailable) {
        setIsUpdateAvailable(true)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return isUpdateAvailable ? (
    <ThemeContext.Consumer>
      {theme => (
        <div>
          <h3 className='note'>An update is available</h3>
          <button onClick={() => window.location.reload()}>
            <h3>Refresh</h3>
          </button>

          <style jsx>
            {`
              div {
                background: transparent;
                border: none;
                border-radius: 25px;
                margin: 10px;
                padding: 5px;
                display: flex;
                text-align: center;
              }

              .note {
                font-size: ${theme.font.size.s};
                color: var(--color-neutral-white);
                margin-right: ${theme.space.s};
                text-align: end;
                display: flex;
                align-items: center;
              }

              button {
                padding: 6px 16px 16px 6px;
                color: var(--color-brand-primary);
                text-align: center;
                background: transparent;
                padding: ${theme.space.inset.s};
                border: 1px solid var(--color-brand-primary);
                border-radius: ${theme.size.radius.default};
                transition: all ${theme.time.duration.default};

                &:hover {
                  color: var(--color-neutral-white);
                  border: 1px solid var(--line-color);
                  background: var(--color-brand-primary);
                }
              }
            `}
          </style>
        </div>
      )}
    </ThemeContext.Consumer>
  ) : null
}
